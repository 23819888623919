<template>
  <div id="courses" class="grey lighten-3 fill-height">
    <v-container>
      <!-- title -->
      <v-row justify="center">
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="pink darken-1">
            mdi-bookshelf
          </v-icon>
          Courses
        </h1>
      </v-row>

      <!-- Add Courses -->
      <v-row justify="center" class="my-4">
        <CoursesCard
          :superLoading="superLoading"
          @setSuperLoading="setSuperLoading"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CoursesCard from "@/components/Home/Manage/Courses/CoursesCard.vue";

export default {
  name: "Courses",
  metaInfo: {
    title: "Courses",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Courses Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: {
    CoursesCard,
  },
  data: () => ({
    superLoading: true,
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },
  },
  mounted() {
    this.superLoading = false;
  },
};
</script>