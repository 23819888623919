<template>
  <div id="editCourseDialog">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Delete dialog -->
    <v-dialog v-model="deleteCourseDialog" persistent max-width="590">
      <v-card v-if="localCourse" :loading="loading || superLoading">
        <v-card-title class="headline">
          Are you sure you want to delete this course ?
        </v-card-title>
        <v-card-subtitle class="mt-1">
          <b>Course:</b> {{ localCourse.courseName }}
        </v-card-subtitle>
        <v-card-text>
          This action cannot be reversed. Please click the "DELETE" button to
          confirm.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            dark
            :loading="loading || superLoading"
            @click="deleteCourse()"
          >
            <v-icon class="mr-1">mdi-trash-can-outline</v-icon> Delete
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading || superLoading"
            @click="deleteCourseDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Course dialog -->
    <v-dialog max-width="800" v-model="courseDialog" persistent>
      <v-card tile v-if="localCourse">
        <!-- Toolbar -->
        <v-toolbar dense dark color="pink darken-1">
          <v-btn icon dark @click="closeCourseDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="hidden-sm-and-down"
            >Edit Course</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- Delete Modal Button -->
            <v-btn
              @click="deleteCourseDialog = true"
              dark
              text
              class="mr-1"
              small
            >
              <v-icon class="mr-1">mdi-trash-can-outline mdi-18px</v-icon>
              Delete
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- Error Row -->
        <div v-if="error" class="mt-5 mb-n3 px-1 px-md-3">
          <v-alert type="error">{{ error }}</v-alert>
        </div>

        <!-- Course Name -->
        <v-card-subtitle class="mt-5 mt-md-9">
          <v-chip class="mx-1">
            <b class="mr-1">{{ localCourse.courseName }}</b> Test Groups :
          </v-chip>
        </v-card-subtitle>

        <v-card-text>
          <v-container class="px-1 px-md-4 py-1" fluid>
            <v-form v-model="valid" @submit.prevent ref="courseForm">
              <v-textarea
                v-model="courseDesc"
                class="mx-1"
                label="Course Description"
                placeholder="Class 9 P-C-M"
                prepend-inner-icon="mdi-sign-text"
                color="deep-purple accent-4"
                counter="500"
                :rules="testGroupRules"
                :disabled="loading || superLoading"
                outlined
                dense
                required
              ></v-textarea>
            </v-form>
          </v-container>

          <v-container class="pa-2" fluid>
            <!-- Test Groups List -->
            <template v-for="(testGroup, i) in localCourse.testGroups">
              <v-list-item :key="testGroup + '_list-item'">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip> {{ i + 1 }}. {{ testGroup }} </v-chip>
                  </v-list-item-title>
                </v-list-item-content>

                <!-- Delete Button -->
                <v-list-item-action>
                  <v-btn icon @click="deleteTestGroup(i)">
                    <v-icon color="red darken-1">
                      mdi-18px mdi-trash-can
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="testGroup + '_divider'"></v-divider>
            </template>

            <!-- Add Test Group Form -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-form
                    class="mt-3 d-flex"
                    v-model="addTestGroupValid"
                    @submit.prevent="addTestGroup"
                    ref="testGroupForm"
                  >
                    <v-text-field
                      color="pink accent-4"
                      v-model="testGroup"
                      type="text"
                      :rules="testGroupRules"
                      label="Test Group Name"
                      placeholder="Class 9 P-C-M"
                      class="mx-1"
                      :disabled="loading || superLoading"
                      outlined
                      dense
                      required
                    ></v-text-field>
                    <!-- Add Test Group Button-->
                    <v-btn
                      @click="addTestGroup"
                      :disabled="loading || superLoading || !addTestGroupValid"
                      icon
                    >
                      <v-icon color="red darken-1"> mdi-18px mdi-plus </v-icon>
                    </v-btn>
                  </v-form>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="saveCourse()"
            color="pink darken-1 white--text"
            :disabled="
              loading ||
              superLoading ||
              !valid ||
              localCourse.testGroups.length < 1
            "
          >
            <v-icon class="mr-1">mdi-content-save mdi-18px</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditCourseDialog",
  props: ["superLoading", "selectedCourse", "courseDialog"],
  watch: {
    //copy selectedCourse to local data when dialog opens
    courseDialog: function (newVal) {
      if (newVal) {
        //if course dialog opened
        this.localCourse = this.selectedCourse;
        this.courseDesc = this.localCourse.desc;
      } else {
        //if course dialog closed
        this.localCourse = null;
      }
    },
  },

  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    deleteCourseDialog: false,

    //form validation
    valid: false,
    addTestGroupValid: false,
    testGroupRules: [
      (value) => !!value || "Required.",
      (value) =>
        value.length > 1 || "Field length should be greater than 1 characters",
      (value) =>
        value.length < 500 || "Field length should be less than 500 characters",
    ],

    //form values
    testGroup: "",
    courseDesc: "",
    localCourse: null,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    deleteTestGroup(index) {
      //remove from testGroup array
      this.localCourse.testGroups.splice(index, 1);
    },

    addTestGroup() {
      var testGroupArr = this.localCourse.testGroups;

      if (testGroupArr.includes(this.testGroup)) {
        this.snackbarText = "Test Group already exists :(";
        this.snackbar = true;
        return;
      }

      testGroupArr.push(this.testGroup);
      this.$set(this.localCourse, "testGroups", testGroupArr);
      this.testGroup = "";
    },

    deleteCourse() {
      this.$emit("deleteCourse");
    },

    closeCourseDialog() {
      this.$emit("closeCourseDialog");
    },

    saveCourse() {
      this.localCourse.desc = this.courseDesc;
      this.$emit("saveCourse", this.localCourse);
    },
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>
