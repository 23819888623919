<template>
  <div id="coursesCard" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <EditCourseDialog
      :superLoading="superLoading"
      :selectedCourse="selectedCourse"
      :courseDialog="courseDialog"
      @setSuperLoading="setLoading"
      @closeCourseDialog="closeCourseDialog"
      @deleteCourse="deleteCourse"
      @saveCourse="saveCourse"
    />

    <v-card :loading="loading || superLoading" class="w-100 pb-4 px-4">
      <!-- Title -->
      <h6 class="text-h6 pt-4">
        <v-icon class="mr-1">mdi-chevron-right</v-icon> Edit Courses:
      </h6>

      <!-- Add Course Form -->
      <v-form
        class="mt-3"
        v-model="valid"
        @submit.prevent="addCourse"
        ref="courseForm"
      >
        <v-container>
          <v-row align="center" no-gutters v-if="error">
            <v-col cols="12">
              <v-alert type="error">{{ error }}</v-alert>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <!-- Input Row w/ Button -->
            <v-col cols="12" sm="10" class="mx-auto">
              <div>
                <v-text-field
                  color="deep-purple accent-4"
                  v-model="courseName"
                  type="text"
                  :rules="courseNameRules"
                  label="Course Name"
                  placeholder="Class 9 P-C-M"
                  class="mx-1"
                  :disabled="loading || superLoading"
                  prepend-icon="mdi-bookshelf"
                  autofocus
                  outlined
                  dense
                  required
                ></v-text-field>

                <v-file-input
                  v-model="courseLogoImg"
                  accept="image/*"
                  label="Course Logo Image"
                  prepend-icon="mdi-image"
                  color="deep-purple accent-4"
                  class="mx-1"
                  :rules="courseLogoImgRules"
                  :disabled="loading || superLoading"
                  show-size
                  dense
                  outlined
                  required
                ></v-file-input>

                <v-textarea
                  v-model="courseDesc"
                  class="mx-1"
                  label="Course Description"
                  placeholder="Class 9 P-C-M"
                  prepend-icon="mdi-sign-text"
                  color="deep-purple accent-4"
                  counter="500"
                  :rules="courseNameRules"
                  :disabled="loading || superLoading"
                  outlined
                  dense
                  required
                ></v-textarea>
              </div>
              <v-btn
                color="pink darken-2 white--text"
                class="float-right"
                :disabled="loading || superLoading || !valid"
                raised
                @click="addCourse"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>Add
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-1 mt-5"></v-divider>
        </v-container>
      </v-form>

      <!-- Display Courses List -->
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="7">
            <template v-for="(course, i) in courses">
              <v-list-item :key="course.courseName">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip> {{ i + 1 }}. {{ course.courseName }} </v-chip>
                  </v-list-item-title>
                </v-list-item-content>

                <!-- Edit Button -->
                <v-list-item-action>
                  <v-btn
                    :disabled="loading || superLoading"
                    @click="editCourseModal(i)"
                  >
                    <v-icon class="mr-2" color="pink darken-1">
                      mdi-18px mdi-notebook-edit</v-icon
                    >
                    Edit
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="course.courseName + '_divider'"></v-divider>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import EditCourseDialog from "@/components/Home/Manage/Courses/EditCourseDialog.vue";

export default {
  name: "CoursesCard",
  props: ["superLoading"],
  components: { EditCourseDialog },
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    courseDialog: false,
    deleteCourseDialog: false,
    selectedCourse: {},
    valid: false,
    addTestGroupValid: false,
    courseName: "",
    courseLogoImg: null,
    courseDesc: "",
    courseNameRules: [
      (value) => !!value || "Required.",
      (value) =>
        value.length > 1 || "Field length should be greater than 1 characters",
      (value) =>
        value.length < 500 || "Field length should be less than 500 characters",
    ],
    courseLogoImgRules: [
      (value) => !!value || "Required.",
      (value) =>
        !value ||
        value.size < 500000 ||
        "Course logo image should be less than 500 KB!",
    ],
    courses: {},
    testGroup: "",
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    closeCourseDialog() {
      this.setLoading(true);
      this.courseDialog = false;
      this.selectedCourse = null;

      //to reset automatic (bug) changes to vuex state
      window.location.reload();
    },

    doesCourseExists(courseName) {
      var existsAlready = false;

      this.courses.some((courseObj) => {
        if (courseObj.courseName == courseName) {
          existsAlready = true;
          return true;
        }
      });

      return existsAlready;
    },

    addCourse() {
      //write locally
      this.error = "";

      if (this.doesCourseExists(this.courseName.toLowerCase())) {
        this.error = "This course already exists :(";
        return;
      }

      const courseObj = {
        courseName: this.courseName.toLowerCase(),
        //default logo img
        logoImg:
          "https://firebasestorage.googleapis.com/v0/b/plusminus-b973d.appspot.com/o/course_logos%2Fother.png?alt=media&token=709bfab0-66a0-4542-adc1-fb9ff42f6b17",
        testGroups: ["demo test group"],
        desc: this.courseDesc,
      };

      this.courses.push(courseObj);

      const payload = {
        courses: this.courses,
        logoImg: this.courseLogoImg,
      };

      //write to server

      this.$store
        .dispatch("addCourse", payload)
        .then(() => {
          this.snackbarText = "Course added successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.setLoading(false);
          this.courseName = "";
          this.courseLogoImg = null;
          this.snackbar = true;
          this.closeCourseDialog();
        });
    },

    editCourseModal(index) {
      this.selectedCourse = this.courses[index];
      this.$set(this.selectedCourse, "arrayPos", index);
      this.selectedCourse.arrayPos = index;
      this.courseDialog = true;
      //reset courses to lose data
    },

    deleteCourse() {
      this.setLoading(true);
      this.error = "";
      //remove from courses array
      this.courses.splice(this.selectedCourse.arrayPos, 1);

      //write to server
      this.$store
        .dispatch("saveCourses", this.courses)
        .then(() => {
          this.snackbarText = "Course deleted successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.setLoading(false);
          this.snackbar = true;
          this.closeCourseDialog();
        });

      this.deleteCourseDialog = false;
    },

    saveCourse(localCourseObj) {
      const courseObj = localCourseObj;
      delete courseObj.arrayPos;

      //add to local courses array
      this.$set(this.courses, localCourseObj.arrayPos, courseObj);

      //write to server
      this.$store
        .dispatch("saveCourses", this.courses)
        .then(() => {
          this.snackbarText = "Course edited successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.setLoading(false);
          this.snackbar = true;
          this.closeCourseDialog();
        });
    },

    fetchCourses() {
      this.$store
        .dispatch("getMetaDoc")
        .then((res) => {
          if (res) {
            this.courses = res.courses;
          }
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
  },
  mounted() {
    this.fetchCourses();
  },
};
</script>
